<template>
  <main class="flex flex-col items-center">
    <main-content-container>
      <h2 class="font-semibold mb-6 mt-3 font-roboto text-xlg text-black">
        {{ $t("positions") }}
      </h2>
      <colli-position />
    </main-content-container>
  </main>
</template>

<script>
import { computed, defineComponent, reactive, watch } from "vue";
import { useStore } from "vuex";

import MainContentContainer from "../../components/global/MainContentContainer.vue";
import {
  AUTH_TYPES,
  COLLI_POSITIONS_TYPES,
  STORE_TYPES,
} from "../../store/types";
import ColliPosition from "./show/ColliPosition.vue";

export default defineComponent({
  components: {
    MainContentContainer,
    ColliPosition,
  },
  setup() {
    const store = useStore();

    /** State */
    const state = reactive({});

    /** COMPUTEDS  */
    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );
    const GET_COLLI_POSITIONS = computed(
      () =>
        store.getters[
          `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITIONS}`
        ]
    );

    function FETCH_COLLI_POSITIONS() {
      store.dispatch(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.FETCH_COLLI_POSITIONS}`,
        {
          id: GET_USER.value.customer_id,
        }
      );
    }

    watch(
      () => GET_USER.value,
      (newValue) => {
        if (newValue != null && newValue.customer_id != null) {
          FETCH_COLLI_POSITIONS(newValue.customer_id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      state,
      GET_COLLI_POSITIONS,
    };
  },
});
</script>
