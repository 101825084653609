<template>
  <div>
    <bottom-up-modal-2
      :isVisible="isVisible"
      :title="
        'Info: ' +
          (colli_position.name
            ? colli_position.name
            : $t('position') + ' ' + colli_position.id)
      "
      @closeModal="$emit('closeModal')"
    >
      <div class="mt-5"></div>
      <div
        v-for="(key, value) of colli_position.data.data"
        :key="key"
        class="flex flex-row even:bg-white-dark p-1"
      >
        <!-- class="flex flex-wrap md:flex-nowrap  gap-10 md:gap-24 mt-5 text-xs sm:text-sm " -->

        <div class="text-md  w-1/2">
          {{ value }}
        </div>
        <div class="text-md mt-2" v-if="key === true">
          <right-icon />
        </div>
        <div class="text-md mt-2" v-else-if="key === false">
          <false-icon />
        </div>
        <div class="text-md  w-1/2" v-else>
          {{ key }}
        </div>
      </div>
    </bottom-up-modal-2>
  </div>
</template>

<script>
import BottomUpModal2 from "../../../components/layout/BottomUpModal2.vue";

export default {
  components: {
    BottomUpModal2,
  },

  emits: ["closeModal", "setBack"],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
    },
    colli_position: {
      type: Object,
      required: true,
    },
  },

  setup() {},
};
</script>
