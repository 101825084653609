<template>
  <div class="p-3" v-if="!showItems">
    <!-- Title -->
    <div class="flex items-center gap-2">
      <div class="font-semibold flex gap-2">
        <div>{{ colli.name }}</div>
      </div>

      <div
        class="cursor-pointer"
        v-if="
          !administrationView &&
            colli.colliPositions != null &&
            colli.colliPositions.length != 0
        "
        @click="$emit('showAllPositions')"
      >
        <div><box-icon /></div>
      </div>

      <div
        class="cursor-pointer ml-5"
        v-if="colli.data != null && colli.data.data != null"
        @click="$emit('showAllData')"
      >
        <div><info-icon :fillClass="'fill-primary'"></info-icon></div>
      </div>

      <div
        class="ml-auto rounded-full w-8 h-8 items-center flex hover:bg-white-dark "
      >
        <div
          class="cursor-pointer"
          v-if="!administrationView"
          @click="$emit('showPositions')"
        >
          <div>
            <position-icon />
          </div>
        </div>

        <div class="cursor-pointer" @click="$emit('editColli')" v-else>
          <div><edit-icon /></div>
        </div>
      </div>
    </div>
    <div
      v-if="colli.data != null && colli.data.data != null"
      class="flex flex-row space-x-16 mt-3"
    >
      <div class="sm:w-auto w-1/2" v-if="!administrationView">
        <div class="text-sm text-grey-fontgrey">
          {{ $t("current_position") }}
        </div>
        <div class="text-md ">
          {{ colli.position ? colli.position.name : "-" }}
        </div>
      </div>

      <div
        v-for="dataField of colli.data.dataModel.dataFields"
        :key="dataField"
      >
        <div class=" flex text-sm gap-9 sm:gap-20">
          <div>
            <div class="text-sm text-grey-fontgrey">
              {{ dataField.key }}
            </div>
            <div
              class="text-md mt-2"
              v-if="colli.data.data[dataField.key] === true"
            >
              <right-icon />
            </div>
            <div
              class="text-md mt-2"
              v-else-if="colli.data.data[dataField.key] === false"
            >
              <false-icon />
            </div>
            <div class="text-md" v-else>
              {{ key }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--- ---------------------- list  ------------------------------>

  <div
    class="bg-white-dark rounded-md w-full p-3 mt-3 mb-3 hover:border-grey-fontgrey"
    v-if="showItems"
    :class="
      GET_COLLI_POSITION.id == colli.id
        ? 'border-t-4 border-primary'
        : 'border border-transparent'
    "
  >
    <div class="p-2 flex items-center">
      <div class="flex flex-col">
        <div class=" flex  font-semibold  box-content md:text-md gap-1">
          <div class="font-semibold">{{ colli.name }}</div>
        </div>
        <div class="text-xs md:text-sm hidden sm:block">
          <div class=" flex items-center text-sm gap-2">
            <div v-if="colli.data != null && colli.data.dataModel != null">
              {{ $t("group") }}: {{ colli.data.dataModel.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto md:mr-7">
        <div class="flex items-center space-x-6 ">
          <edit-icon
            class="cursor-pointer"
            @click="$emit('editColliPosition')"
          />
          <trash-icon
            class="cursor-pointer"
            @click="$emit('deleteColliPosition')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

import EditIcon from "../../../components/icons/EditIcon.vue";
import InfoIcon from "../../../components/icons/InfoIcon.vue";
import PositionIcon from "../../../components/icons/PositionIcon.vue";
import TrashIcon from "../../../components/icons/TrashIcon.vue";
import { COLLI_POSITIONS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
  name: "ProductCarrierInfoContainer",
  components: { PositionIcon, EditIcon, TrashIcon, InfoIcon },

  /** Emits :  */

  emits: [
    "open",
    "deleteColliPosition",
    "showAllPositions",
    "editColliPosition",
    "showPositions",
    "showAllData",
  ],

  /** Props */
  props: {
    administrationView: {
      type: Boolean,
      default: false,
    },
    colli: {
      type: Object,
    },
    showItems: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    const GET_COLLI_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITION}`
      ];
    });

    return {
      GET_COLLI_POSITION,
    };
  },
});
</script>
