<template>
  <bottom-up-modal
    :isVisible="isVisible"
    @closeModal="$emit('closeModal')"
    :title="
      GET_COLLI_POSITION != null && GET_COLLI_POSITION.id != null
        ? $t('edit_position')
        : $t('add_position')
    "
  >
    <!-- position form -->
    <position-form
      @closeModal="$emit('closeModal')"
      @setBack="set_back()"
      @updateColliPosition="UPDATE_COLLI_POSITION()"
      @createColliPosition="CREATE_COLLI_POSITION()"
    />

    <div class="mt-3 w-full h-px bg-primary-light"></div>
    <div>
      <div class="font-semibold text-lg mt-8 mb-4">
        {{ $t("positions") }}
      </div>
      <searchbar
        :id="'search'"
        v-model="state.search"
        :label="$t('search')"
        :labelClass="'bg-white'"
        :activeColor="'primary'"
        class="w-1/2 bg-white mb-3"
        :placeHolder="$t('designation')"
        @update="
          (value) => {
            state.search = value;
          }
        "
      />
    </div>

    <div
      v-if="filteredColliPosition != null && filteredColliPosition.length > 0"
    >
      <colli-position-info-container
        v-for="colli of filteredColliPosition"
        :key="colli.id"
        :colli="colli"
        :showItems="true"
        @deleteColliPosition="delete_colli(colli)"
        @editColliPosition="edit_colli(colli)"
      />
    </div>

    <!--empty feedback-->
    <div v-else>
      <empty-list-feedback
        class="pt-10 flex justify-center items-center"
        v-if="
          filteredColliPosition == null || filteredColliPosition.length == 0
        "
        :text="$t('no_positions')"
      ></empty-list-feedback>
    </div>

    <!--- delete modal -->
    <delete-colli-position-modal
      :isVisible="state.openDeleteModal"
      @closeModal="state.openDeleteModal = false"
      @delete="DELETE_COLLI()"
    />
  </bottom-up-modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import { getData } from "../../../common/dataHelper";
import ColliPositionInfoContainer from "../components/ColliPositionInfoContainer.vue";
import {
  AUTH_TYPES,
  COLLI_POSITIONS_TYPES,
  CUSTOMER_COLLIS_TYPES,
  DATA_FIELDS_TYPES,
  DATA_MODELS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { computed, watch } from "@vue/runtime-core";
import DeleteColliPositionModal from "./DeleteColliPositionModal.vue";
import PositionForm from "../components/ColliPositionForm.vue";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";

export default {
  components: {
    BottomUpModal,
    Searchbar,
    ColliPositionInfoContainer,
    DeleteColliPositionModal,
    PositionForm,
    EmptyListFeedback,
  },

  emits: ["closeModal"],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    fetchProp: {
      type: Boolean,
      default: true,
    },
    placeHolder: {
      type: String,
    },
    colli: {
      type: Object,
    },
  },

  setup(props) {
    const store = useStore();
    /**
     *  Refs
     */

    /**
     *  State
     */
    const state = reactive({
      isOpen: false,
      openDeleteModal: false,
      changeTitle: false,
      search: "",
    });

    /*
     * Computeds
     */

    const GET_COLLI_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITIONS}`
      ];
    });

    const GET_COLLI_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITION}`
      ];
    });

    const GET_DELETE_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_DELETE_COLLI_POSITION}`
      ];
    });

    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const filteredColliPosition = computed(() => {
      if (GET_COLLI_POSITIONS.value == null) {
        return;
      }
      return GET_COLLI_POSITIONS.value.filter((colli) => {
        if (state.search == "" || state.search == null) {
          return true;
        }
        if (
          colli.name != null &&
          colli.name.toLowerCase().match(state.search.toLowerCase())
        ) {
          return true;
        }
        const dataAttributes = getData(colli, "data.data");

        if (dataAttributes) {
          for (const key in dataAttributes) {
            if (Object.hasOwnProperty.call(dataAttributes, key)) {
              const element = dataAttributes[key];
              if (
                element &&
                element
                  .toString()
                  .toLowerCase()
                  .match(state.search.toLowerCase())
              )
                return true;
            }
          }
        }

        return false;
      });
    });

    /**
     * Functions
     */

    const DELETE_COLLI = async () => {
      const colliData = {
        customer_id: GET_DELETE_COLLI.value.customer_id,
        id: GET_DELETE_COLLI.value.id,
        colli_position: GET_DELETE_COLLI.value,
      };
      if (GET_DELETE_COLLI.value == null || GET_DELETE_COLLI.value.id == null) {
        return;
      }

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "delete"
      );
      await store.dispatch(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.DELETE_COLLI_POSITION}`,
        colliData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "delete"
      );
      state.openDeleteModal = false;
    };

    function FETCH_COLLI_POSITIONS() {
      store.dispatch(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.FETCH_COLLI_POSITIONS}`,
        {
          id: GET_USER.value.customer_id,
        }
      );
    }

    function FETCH_DATA_MODELS() {
      store.dispatch(
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.FETCH_DATA_MODELS}`,
        GET_USER.value.customer_id
      );
    }
    function FETCH_COLLIS() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        {
          id: GET_USER.value.customer_id,
        }
      );
    }

    function FETCH_DATA_FIELDS() {
      store.dispatch(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.FETCH_DATA_FIELDS}`,
        GET_USER.value.customer_id
      );
    }

    const SET_COLLI_POSITION = (colli) => {
      return store.commit(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.SET_COLLI_POSITION}`,
        colli
      );
    };

    const SET_DELETE_COLLI = (colli) => {
      return store.commit(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.SET_DELETE_COLLI_POSITION}`,
        colli
      );
    };

    // Update Colli
    const UPDATE_COLLI_POSITION = async (input_id) => {
      if (
        GET_COLLI_POSITION.value == null ||
        GET_COLLI_POSITION.value.id == null
      ) {
        return;
      }
      const colliData = {
        customer_id: GET_COLLI_POSITION.value.customer_id,
        id: GET_COLLI_POSITION.value.id,
        create_colli: GET_COLLI_POSITION.value,
      };
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        input_id
      );

      await store.dispatch(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.UPDATE_COLLI_POSITION}`,
        colliData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        input_id
      );
      set_back();
    };

    // CREATE COLLI
    const CREATE_COLLI_POSITION = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "create_colli"
      );
      await store.dispatch(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.CREATE_COLLI_POSITION}`,
        GET_USER.value.customer_id
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "create_colli"
      );

      set_back();
    };

    const delete_colli = (colli) => {
      SET_DELETE_COLLI(colli);
      state.openDeleteModal = true;
    };

    const edit_colli = (colli) => {
      SET_COLLI_POSITION(colli);
      state.openModal = true;
    };

    const set_back = () => {
      SET_COLLI_POSITION({});
      state.isOpen = false;
    };

    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue == true) {
          if (GET_USER.value != null && GET_USER.value.id != null) {
            FETCH_DATA_MODELS(GET_USER.value.customer_id);
            FETCH_DATA_FIELDS(GET_USER.value.customer_id);
            FETCH_COLLIS(GET_USER.value.customer_id);
            if (props.fetchProp == true) {
              FETCH_COLLI_POSITIONS(GET_USER.value.customer_id);
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      state,
      store,
      UPDATE_COLLI_POSITION,
      CREATE_COLLI_POSITION,
      delete_colli,
      edit_colli,
      set_back,
      filteredColliPosition,
      DELETE_COLLI,
      GET_COLLI_POSITION,
      GET_COLLI_POSITIONS,
    };
  },
};
</script>
