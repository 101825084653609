<template>
  <div class="p-3" v-if="!showItems">
    <!-- Title -->
    <div class="flex items-center gap-2">
      <div class="font-semibold flex gap-2">
        <div>{{ colli_position.name }}</div>
      </div>
      <div
        class="cursor-pointer"
        v-if="colli_position.data != null && colli_position.data.data != null"
        @click.stop="$emit('showAllData')"
      >
        <div><info-icon :fillClass="'fill-primary'"></info-icon></div>
      </div>
      <div
        class="ml-auto rounded-full w-10 h-10 items-center flex hover:bg-primary-lightest "
      >
        <div class="cursor-pointer" @click.stop="$emit('editColliPosition')">
          <div class="ml-2"><edit-icon /></div>
        </div>
      </div>
    </div>

    <div
      v-if="colli_position.data != null && colli_position.data.data != null"
      class="flex flex-row flex-wrap justify-start mt-3 gap-8"
    >
      <div v-for="(key, value) of colli_position.data.data" :key="key">
        <div class=" flex text-sm gap-9 sm:gap-20">
          <div>
            <div class="text-sm text-blue">
              {{ value }}
            </div>
            <div class="text-md mt-2" v-if="key === true">
              <right-icon />
            </div>
            <div class="text-md mt-2" v-else-if="key === false">
              <false-icon />
            </div>
            <div class="text-md" v-else>
              {{ key }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!----------------------------  list  ---------------------------------->
  <div class="bg-white-dark rounded-md">
    <div class="bg-white-dark rounded-md w-full p-2 mt-2" v-if="showItems">
      <div class="p-2 flex items-center">
        <div class="flex flex-col">
          <div class=" flex  font-semibold  box-content md:text-md gap-1">
            <div class="font-semibold">{{ colli_position.name }}</div>
          </div>
          <div class="text-xs md:text-sm hidden sm:block">
            <div class=" flex items-center text-sm gap-2">
              <div>{{ $t("channel") }}: {{ "23.09.2020" }} |</div>
              <div>{{ $t("group") }}: {{ " Gruppe 2" }}</div>
            </div>
          </div>
        </div>
        <div class="ml-auto md:mr-7">
          <div class="flex items-center space-x-6 ">
            <edit-icon
              class="cursor-pointer"
              @click="$emit('editColliPosition')"
            />
            <trash-icon
              class="cursor-pointer"
              @click="$emit('deleteColliPosition')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import EditIcon from "../../../components/icons/EditIcon.vue";
import InfoIcon from "../../../components/icons/InfoIcon.vue";
import TrashIcon from "../../../components/icons/TrashIcon.vue";

export default defineComponent({
  name: "ProductCarrierInfoContainer",
  components: { EditIcon, TrashIcon, InfoIcon },

  /** Emits :  */

  emits: [
    "open",
    "editColliPosition",
    "showAllPositions",
    "deleteColliPosition",
    "showAllData",
  ],

  /** Props */
  props: {
    administrationView: {
      type: Boolean,
      default: false,
    },
    colli_position: {
      type: Object,
    },
    showItems: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
