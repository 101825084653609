<template>
  <div>
    <div class="flex items-center gap-3 w-full mb-4">
      <!--- input search -->
      <searchbar
        :id="'search'"
        :label="$t('search')"
        v-model="state.search"
        :labelClass="'bg-white-dark'"
        :activeColor="'primary'"
        class="w-full sm:w-1/3 "
        :placeHolder="$t('search')"
      />
      <!-- action button -->
      <div
        class="ml-auto flex items-center cursor-pointer bg-primary px-2 py-2 rounded-lg "
        @click="add_colli_position(colli_position)"
      >
        <div class="mr-0 sm:mr-2"><plus-icon :fillClass="'fill-white'" /></div>
        <div class="text-md font-semibold hidden sm:block text-white">
          {{ $t("add_position") }}
        </div>
      </div>
    </div>

    <!-- Content -->

    <div
      v-if="filteredColliPositions != null && filteredColliPositions.length > 0"
    >
      <div
        :id="'colliPosition-' + colli_position.id"
        class="bg-white cursor-pointer rounded-md
      h-auto flex-1 gap-8 mb-3
    hover:border-grey-fontgrey"
        :class="
          GET_COLLI_POSITION.id == colli_position.id
            ? 'border-t-4 border-primary'
            : 'border border-transparent'
        "
        v-for="colli_position of filteredColliPositions"
        :key="colli_position.id"
        :colli_position="colli_position"
        @click="edit_colli_position(colli_position)"
      >
        <!--  ON ACTIVE   SHOW IF ...  -->

        <colli-positions-container
          :colli_position="colli_position"
          :administrationView="true"
          @showAllData="state.colli_position = colli_position"
          @editColliPosition="edit_colli_position(colli_position)"
        />
      </div>
    </div>
    <!--empty feedback-->
    <div v-else>
      <empty-list-feedback
        class="pt-10 flex justify-center items-center"
        v-if="
          filteredColliPositions == null || filteredColliPositions.length == 0
        "
        :text="$t('no_positions')"
      ></empty-list-feedback>
    </div>

    <!--add position modal -->
    <add-colli-position-modal
      :fetchProp="false"
      :isVisible="state.openModal"
      @closeModal="set_back()"
    />
  </div>

  <!--position data modal -->
  <colli-position-data-modal
    v-if="state.colli_position != null && state.colli_position.id != null"
    :colli_position="state.colli_position"
    :isVisible="state.colli_position != null && state.colli_position.id != null"
    @closeModal="state.colli_position = {}"
  />
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getData } from "../../../common/dataHelper";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import PlusIcon from "../../../components/icons/PlusIcon.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import { COLLI_POSITIONS_TYPES, STORE_TYPES } from "../../../store/types";
import ColliPositionsContainer from "../components/ColliPositionsContainer.vue";
import AddColliPositionModal from "../modals/AddColliPositionModal.vue";
import ColliPositionDataModal from "../modals/ColliPositionDataModal.vue";

export default defineComponent({
  components: {
    ColliPositionsContainer,
    Searchbar,
    PlusIcon,
    AddColliPositionModal,
    ColliPositionDataModal,
    EmptyListFeedback,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      search: "",
      openModal: false,
      showColliId: null,
      colli_position: {},
    });

    /*
     * Computeds
     */
    const GET_COLLI_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITIONS}`
      ];
    });
    const GET_COLLI_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITION}`
      ];
    });

    const filteredColliPositions = computed(() => {
      if (GET_COLLI_POSITIONS.value == null) {
        return;
      }
      return GET_COLLI_POSITIONS.value.filter((colli) => {
        if (state.search == "" || state.search == null) {
          return true;
        }
        if (
          colli.name != null &&
          colli.name.toLowerCase().match(state.search.toLowerCase())
        ) {
          return true;
        }
        const dataAttributes = getData(colli, "data.data");

        if (dataAttributes) {
          for (const key in dataAttributes) {
            if (Object.hasOwnProperty.call(dataAttributes, key)) {
              const element = dataAttributes[key];
              if (
                element &&
                element
                  .toString()
                  .toLowerCase()
                  .match(state.search.toLowerCase())
              )
                return true;
            }
          }
        }

        return false;
      });
    });

    /** Functions */

    const SET_COLLI_POSITION = (colli_position) => {
      return store.commit(
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.SET_COLLI_POSITION}`,
        colli_position
      );
    };

    const add_colli_position = () => {
      SET_COLLI_POSITION({});
      state.openModal = true;
    };
    const set_back = () => {
      SET_COLLI_POSITION({});
      state.openModal = false;
    };
    const edit_colli_position = (colli_position) => {
      SET_COLLI_POSITION(colli_position);
      state.openModal = true;
    };
    const jumpToColliPosition = (colli) => {
      // state.showAllPositions = true;
      state.showColliId = state.showColliId == colli.id ? null : colli.id;
      setTimeout(() => {
        let element = document.getElementById("colliPosition-" + colli.id);
        if (
          element != null &&
          element.scrollIntoView != undefined &&
          state.showColliId == colli.id
        ) {
          element.scrollIntoView();
        }
      }, 20);
    };
    /**
     *  Mounted
     */

    return {
      store,
      state,
      route,
      add_colli_position,
      edit_colli_position,
      GET_COLLI_POSITIONS,
      GET_COLLI_POSITION,
      filteredColliPositions,
      set_back,
      jumpToColliPosition,
    };
  },
});
</script>
