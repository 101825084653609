<template>
  <div class="flex flex-col mb-5" v-if="GET_COLLI_POSITION != null">
    <div>
      <div class="flex w-full flex-nowrap sm:flex-wrap  gap-3">
        <input-text
          :id="'name'"
          :label="$t('designation')"
          :name="'name'"
          :labelClass="'bg-white'"
          v-model="GET_COLLI_POSITION.name"
          class="w-1/3 mb-5 sm:flex-1"
        ></input-text>

        <input-select
          :id="'group'"
          :activeColor="'grey-fontgrey'"
          :label="$t('group')"
          :labelClass="'bg-white'"
          class="w-1/3 mb-5 sm:flex-1"
          :background="'bg-white'"
          :nameColumn="'name'"
          :isSearch="true"
          ref="dropdown"
          :options="GET_GROUPS"
          :activeItems="
            GET_COLLI_POSITION.data != null &&
            GET_COLLI_POSITION.data.dataModel != null
              ? [GET_COLLI_POSITION.data.dataModel]
              : []
          "
          @changeItem="
            (item) => {
              select_group(item);
              $refs.dropdown.close();
            }
          "
        />
      </div>

      <div class="w-full">
        <input-select
          :id="'superior_product_carrier'"
          :activeColor="'grey-fontgrey'"
          :name="'superior_product_carrier'"
          :label="$t('superior_product_carrier')"
          :labelClass="'bg-white'"
          :background="'bg-white'"
          :nameColumn="'name'"
          :isSearch="true"
          ref="dropdown"
          class="w-full sm:w-1/3 mb-5 sm:flex-1"
          :options="GET_COLLIS"
          :activeItems="
            GET_COLLI_POSITION.collis ? GET_COLLI_POSITION.collis : []
          "
          @changeItem="
            (item) => {
              select_colli(item);
              $refs.dropdown.close();
            }
          "
        />
      </div>
    </div>
    <!------------------- individual fields  --------------->
    <div
      v-if="
        GET_COLLI_POSITION.data != null &&
          GET_COLLI_POSITION.data.dataModel != null &&
          GET_COLLI_POSITION.data.dataModel.dataFields != null
      "
    >
      {{ $t("individual_fields") }}

      <div
        class="flex w-full flex-wrap gap sm:gap-3 mt-5"
        v-if="GET_COLLI_POSITION != null"
      >
        <div
          class="flex sm:w-1/3-3  mb-2"
          v-for="field of GET_COLLI_POSITION.data.dataModel.dataFields"
          :key="field.id"
        >
          <input-text
            v-if="field.type != 'boolean'"
            class="w-full"
            :id="field.key"
            :type="field.type == 'numeric' ? 'number' : 'text'"
            :label="
              field.type == 'numeric' && field.required == true
                ? field.key + ' *' + ' (Zahl)'
                : field.type == 'numeric' && field.required == false
                ? field.key + ' (Zahl)'
                : field.type == 'string' && field.required == true
                ? field.key + ' *' + ' (Text)'
                : field.type == 'string' && field.required == false
                ? field.key + ' (Text)'
                : field.key
            "
            :name="field.key"
            :labelClass="'bg-white'"
            v-model="GET_COLLI_POSITION.data.data[field.key]"
          >
          </input-text>

          <input-checkbox
            class="w-full"
            :id="field.key"
            :label="field.key"
            :name="field.key"
            :labelClass="'bg-white'"
            :modelValue="GET_COLLI_POSITION.data.data[field.key]"
            @change="
              GET_COLLI_POSITION.data.data[field.key] =
                GET_COLLI_POSITION.data.data[field.key] == true ? false : true
            "
            v-else
          >
          </input-checkbox>
        </div>
      </div>
    </div>

    <!-- _______________________________________________-->

    <div class="flex " v-if="GET_COLLI_POSITION.id == null">
      <custom-button
        :id="'create_colli'"
        class="h-11 w-full sm:w-1/4 sm:ml-auto"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :text="$t('add')"
        @click="$emit('createColliPosition')"
      />
    </div>

    <div class="mt-5 flex gap-4" v-else>
      <custom-button
        class="h-11 w-full sm:w-1/4 ml-auto"
        :bgColor="'bg-grey-fontgrey'"
        :textColor="'text-white'"
        :text="$t('abort')"
        @click="$emit('setBack')"
      />
      <custom-button
        class="h-11 w-full sm:w-1/4"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :text="$t('edit_2')"
        :id="'update'"
        @click="$emit('updateColliPosition')"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import InputText from "../../../components/input/InputText.vue";
import InputSelect from "../../../components/input/InputSelect.vue";
import CustomButton from "../../../components/input/CustomButton.vue";
import { useStore } from "vuex";
import {
  COLLI_POSITIONS_TYPES,
  CUSTOMER_COLLIS_TYPES,
  DATA_MODELS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { useRouter } from "vue-router";
import InputCheckbox from "../../../components/input/InputCheckbox.vue";

export default {
  name: "AddProductCarrier",
  components: { InputText, InputSelect, CustomButton, InputCheckbox },
  emits: [
    "open",
    "closeModal",
    "setBack",
    "updateColliPosition",
    "createColliPosition",
  ],

  setup() {
    const store = useStore();
    const router = useRouter();
    /**
     *  Refs
     */
    /**
     *  State
     */
    const state = reactive({
      isOpen: false,
      openDeleteModal: false,
      changeTitle: false,
    });

    /*
     * Computeds
     */

    const GET_COLLI_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITION}`
      ];
    });
    const GET_COLLI_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITIONS}`
      ];
    });

    const GET_GROUPS = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.GET_DATA_MODELS}`
      ];
    });
    const GET_COLLIS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLIS}`
      ];
    });

    // const GET_COLLI_TAG = computed(() => {
    //   return store.getters[
    //     `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_TAG}`
    //   ];
    // });

    /**
     * Functions
     */

    const select_group = (item) => {
      if (GET_COLLI_POSITION.value != null) {
        if (GET_COLLI_POSITION.value.data == null) {
          GET_COLLI_POSITION.value.data = { data: {} };
        }
        if (GET_COLLI_POSITION.value.data.dataModel == item) {
          GET_COLLI_POSITION.value.data.dataModel = null;
        } else {
          GET_COLLI_POSITION.value.data.dataModel = item;
        }
      }
    };

    const select_colli = (item) => {
      if (GET_COLLI_POSITION.value.collis == null) {
        GET_COLLI_POSITION.value.collis = [];
      }
      if (Array.isArray(GET_COLLI_POSITION.value.collis)) {
        const findIndex = GET_COLLI_POSITION.value.collis.findIndex(
          (oneColli) => {
            return oneColli.id == item.id;
          }
        );
        if (findIndex != -1) {
          GET_COLLI_POSITION.value.collis.splice(findIndex, 1);
        } else {
          GET_COLLI_POSITION.value.collis.push(item);
        }
      }
    };

    return {
      state,
      router,
      store,
      GET_COLLI_POSITION,
      GET_COLLI_POSITIONS,
      GET_GROUPS,
      GET_COLLIS,
      select_colli,
      select_group,
    };
  },
  // if (GET_COLLI_POSITION.value != null) {
  //   if (GET_COLLI_POSITION.value.collis == item) {
  //     GET_COLLI_POSITION.value.collis = null;
  //   } else {
  //     GET_COLLI_POSITION.value.collis = item;
  //   }
  // }
};
</script>

<style lang="scss">
.customer-link {
  &.router-link-exact-active {
    @apply bg-grey-lightgrey;
  }
}
</style>
